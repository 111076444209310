<template>
  <div class="batchBox">
    <el-row
      class="accountCon"
    >
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 商品信息
          <img src="@/assets/images/triangle.png" alt="">
        </div>
      </el-col>
      <el-col class="rightCon" :span="24">
        <el-form
          ref="detail"
          label-position="right"
          label-width="170px"
          :rules="rules"
          :model="detail"
          class="formBox"
        >

          <el-form-item label="积分商品名称:" prop="name">
            <el-input
              v-model="detail.name"
              class="w300"
              maxlength="150"
              placeholder="请输入积分商品名称"
              show-word-limit
            />
          </el-form-item>

          <el-form-item label="积分价格:" prop="price">
            <el-input
              v-model="detail.price"
              class="w300"
              clearable
              placeholder="请输入积分价格"
            >
            <span slot="append">点</span>
            </el-input>
          </el-form-item>
          <el-form-item label="是否上架:">
            <el-radio-group v-model="detail.status">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="banner图上传:" >
            <el-upload
              action=""
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadFile1"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="详情图上传:" >
            <el-upload
              action=""
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadFile2"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="积分商品备注:" >
            <el-input
              v-model="detail.desc"
              class="w600"
              placeholder="请填写备注"
              type="textarea"
            />
          </el-form-item>

          <div cla  ss="conMsg">
            <el-button type="primary"
            @click="shift()"
            size="mini"
            class="mainBtn"
            >{{btn_text}}</el-button>
            <div class="title">
              <i class="el-icon-collection-tag" />列表
            </div>
            <!--列表-->
            <el-row type="flex" justify="space-between">
              <el-col :span="24">
                <el-table
                  :data="goods"
                  size="small"
                  border
                  :cell-style="$style.cellStyle"
                  :header-cell-style="$style.rowClass"
                  @selection-change="handleSelected"
                >
                  <el-table-column type="selection" width="50" />
                  <el-table-column label="* 商品名称" width="220" prop="name">
                    <template slot-scope="{ row, $index }">
                      <el-select
                        v-model="row.name"
                        size="mini"
                        value-key="id"
                        placeholder="请选择商品"
                        filterable
                        :remote-method="remoteMethod"
                        @change="val => selectChange(val, $index)"
                      >
                        <el-option
                          v-if="btn_type==1"
                          v-for="item in product"
                          :key="item.id"
                          :label="item.zu_name"
                          :value="item.id"
                        />
                        <el-option
                          v-if="btn_type==2"
                          v-for="item in product"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </template>
                  </el-table-column>

                <el-table-column label="* 数量" width="160">
                  <template scope="{ row, $index }">
                    <el-input-number

                      v-model="row.number"
                      size="mini"
                      :min="1"
                      @change="(value) => handleChange(value, $index, 'goods')"
                    />
                  </template>
                </el-table-column>
                  <el-table-column label="* 商品/服务类型" width="200">
                    <template slot-scope="{ row }">
                      <el-radio
                        v-if="row.is_project == 2 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')"
                        v-model="row.project_type"
                        label="1"
                      >按次</el-radio>
                      <el-radio
                        v-if="row.is_project == 2 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')"
                        v-model="row.project_type"
                        label="2"
                      >按天</el-radio>
                      <span v-if="row.is_project == 1 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')">--</span>
                    </template>
                  </el-table-column>
                 <el-table-column label="* 服务次/天数" width="300">
                    <template slot-scope="{ row }">
                      <el-input
                        v-if="
                          row.project_type == 1 &&
                          row.is_project == 2
                        "
                        v-model="row.service_number"
                        class="w150"
                        size="mini"
                        type="span"
                      >
                        <span slot="append">次</span>
                      </el-input>
                      <el-input
                        v-if="
                          row.project_type == 2 &&
                          row.is_project == 2
                        "
                        v-model="row.service_days"
                        class="w150"
                        size="mini"
                        type="span"
                      >
                        <span slot="append">天</span>
                      </el-input>
                      <span v-if="row.is_project == 1">--</span>
                    </template>
                  </el-table-column>

                </el-table>
                <div class="flexSb mt20">
                  <div>
                    <el-button
                      type="success"
                      icon="el-icon-plus"
                      size="mini"
                      @click="handleProduct('add')"
                    >新增</el-button>
                    <el-button
                      type="danger"
                      icon="el-icon-minus"
                      size="mini"
                      @click="handleProduct('reduce')"
                    >移除</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="btnBox">
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('detail')"
          >完成，提交商品</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { uploadPointGoodsImage,goodsList,addPointGoods } from '@/api/goods';
import { getCardList } from "@/api/card";
class Detail {
    name='';
    price='';
    status=1;
    files=[];
    goods_or_coupon=[];
}
class Rules {
  name = [
    { required: true, message: '请填写积分商品名称', trigger: 'blur' }
  ];
  price = [
    { required: true, message: '请填写商品积分价格', trigger: 'blur' }
  ];
}
class Options {
  type=[
    { id: 1, name: '产品' },
    { id: 2, name: '优惠券' }
  ];
}
class Goods {
  id = '';
  name = '';
  is_project = '';
  kingdee_code = '';
  project_type = '';
  number = 0;
  service_days=0;
  service_number=0;
}
class Coupon{
  id = '';
  name = '';
  number = 0;
}
export default {
  props: {

  },

  data () {
    return {
      options: new Options(), // 选项
      detail: new Detail(),
      rules: new Rules(),
      goods: [new Goods()], // 商品列表
      product: [], // 商品列表
      dialogImageUrl: '',
      dialogVisible: false,
      btn_type:1,
      btn_text:"切换至优惠券",
    }
  },

  mounted () {
    // if(this.$route.query.id )this.getDetail()
    // this.getOptions()
    this.getGoods()
  },
  methods: {
      handleRemove(file, fileList) {
        // console.log(file, fileList);
        const files = this.detail.files;
        for(let i = 0 ;i<files.length;i++)
        {
          if(files[i].uid==file.uid)
          {
            files.splice(i,1);
            break;
          }
        }
        this.detail.files = files;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      uploadFile1(file,filelist){
        const files = this.detail.files;
        const formData = new FormData();
        formData.append("file", file.file);
        const uid = file.file.uid;
        // formData.append("type", 1);
        uploadPointGoodsImage(formData).then(res => {
          if(res.code == 200)
          {
            let data = {};
            data.url = res.data.url;
            data.type = 1;
            data.uid = uid;
            files.push(data);
            this.detail.files=files;
          }else{

          }
        });
        console.log(this.detail.files);
      },
      uploadFile2(file,filelist){
        const files = this.detail.files;
        const formData = new FormData();
        formData.append("file", file.file);
        const uid = file.file.uid;
        console.log(uid);

        uploadPointGoodsImage(formData).then(res => {
          if(res.code == 200)
          {
            let data = {};
            data.url = res.data.url;
            data.type = 2;
            data.uid = uid;
            files.push(data);
            this.detail.files=files;
          }else{

          }
        });
      },
      shift(){
        // console.log(this.btn_type);
        if(this.btn_type == 1)
        {
          this.btn_type = 2;
          this.btn_text = "切换至商品/服务";
          this.getCoupons();
          // console.log(this.btn_text);
        }else{
          this.btn_type = 1;
          this.btn_text = "切换至优惠券";
          this.getGoods();
          // console.log(this.btn_text);
        }
        this.goods = [new Goods()];
      },
      // 商品数据
      getGoods (name) {
        goodsList({ page: 1, limit: 100000, zu_name: name || '' }).then(
          res => {
            this.product = res.list
          }
        )
      },
      // 商品操作
      handleProduct (type) {
        switch (type) {
          case 'add':
            let tip = 0
            this.goods.forEach(item => {
              if (item.id == '') {
                tip += 1
              }
            })
            if (tip > 0) {
              this.$message({
                message: '请补全列表中为空商品。',
                type: 'warning'
              })
            } else {
              this.goods.push(new Goods())
            }
            break
          case 'reduce':
            if (this.select.length === 0) {
              this.$message({
                message: '请选择想要移除的商品项。',
                type: 'warning'
              })
            }
            this.goods = this.goods.filter(
              val => this.select.findIndex(item => item === val.id) < 0
            )
            this.getSummaries('goods')
            break
        }
      },
      // 更改删除项
      handleSelected (row) {
        this.select = row.map(val => val.id)
      },
      // // 商品选择前
      // selectFocus () {
      //   if (!this.detail.uid) return this.$message.warning('请先选择客户哦！')
      // },
      // 搜索商品
      remoteMethod (e) {
        if(this.btn_type==1)
        {
          this.getGoods(e)
        }else{
          this.getCoupons(e)
        }
      },
      // 选择商品
      selectChange (value, index) {
        const cur = this.product.find(item => item.id === value)
        this.$set(this.goods, index, Object.assign({ is_gift: 0 }, this.goods[index], cur))
        this.goods[index].number = 1.00
        this.handleChange (this.goods[index].number, index, 'goods')

      },
      // 商品数量
      handleChange (value, index, type) {
        if (type == 'goods') {
          this.goods[index].littleCount = (
            value * this.goods[index].pay_price
          ).toFixed(2)
          // this.getSummaries('goods')
        } else {
          this.privilegeList[index].littleCount = (
            value * this.privilegeList[index].pay_price
          ).toFixed(2)
          // this.getSummaries('privilege')
        }
      },
      // 优惠券数据
      getCoupons (name) {
        getCardList({ page: 1, limit: 100000, name: name || '' }).then(
          res => {
            this.product = res.list
          }
        )
      },
      submitForm(){
        this.detail.goods_or_coupon = this.goods
        addPointGoods({...this.detail,type:this.btn_type}).then(
          res=>{
              if(res.code==200)
              {
                this.$message({
                  message: '添加完成',
                  type: 'success'
                });
                this.$router.push({ name: '积分商品列表'})
              }else{
                this.$message.error('添加失败');
              }
          })
      },
  }
}
</script>

<style lang="scss" scoped>
.accountCon{
    background: #fff;
    .leftCon{
      background: #fcfcfc;
      border-right: 1px solid #eee;
      position: absolute;
    height: 100%;
      >div{
        line-height: 48px;
        color: #fff;
        background: #9EA7B4;
        width: 100%;
        padding-left: 80px;
        font-size: 14px;
        box-sizing: border-box;
        img{
          position: absolute;
          right: -30px;
          width: 40px;
          height: 48px;
        }
      }
      .label1{
        position: absolute;
        top: 40px;
      }
      .label2{
        position: absolute;
        top: 270px;
      }
    }
    .rightCon{
      padding: 40px 0 0 260px;
      .btn{
        margin: 20px 0 50px  80px;
      }
      .btnBox{
      width: 100%;
      text-align: center;
    }
      .mainBtn{
        margin: 30px 0 0 0px;
      }
      .phoneTip{
        font-size: 12px;
        color: #999;
      }
      .formBox{
      display: flex;
      flex-wrap: wrap;
      .el-form-item{
        width: 100%;
      }
    }
    }
}
</style>
